import dayjs from 'dayjs';
import { NextPageContext } from 'next';

import { compileSync } from '@aftership/atta-engine/renderer';
import { ResponseBody, Shops, internalHostname, request } from 'returns-logics';

import { loader } from './loader';
import { getSchema } from './schema';
import { AttaProps, PathName } from './type';

type SharedConfigs = {
  whitelist: 'after_created_at' | 'all' | 'none';
  after_created_at: string;
};

export const checkAttaEditorEnable = (createdAt: string, sharedConfigs: SharedConfigs): boolean => {
  const { whitelist, after_created_at } = sharedConfigs;

  if (whitelist === 'all') {
    return true;
  } else if (whitelist === 'none') {
    return false;
  } else if (whitelist === 'after_created_at') {
    return dayjs(createdAt).isAfter(dayjs(after_created_at));
  }

  return false;
};

type DataMap = Record<PathName, string>;

export const ATTA_EDITOR_KEY = 'atta-editor';

export const getServerSideAttaProps = async (
  context: NextPageContext,
  isPreview: boolean,
  AM_API_KEY: string,
  shopInfo?: Shops,
): Promise<AttaProps | null> => {
  let isAttaEditor: boolean;

  if (isPreview) {
    const { query, req, res } = context;
    if (query.preview === 'true') {
      // 首次通过 api/preview 进入
      isAttaEditor = query.atta_editor === 'true';
      const cookies = res?.getHeader('Set-Cookie');
      const cookie = `${ATTA_EDITOR_KEY}=${isAttaEditor.toString()}`;
      if (cookies instanceof Array) {
        cookies.push(cookie);
        res?.setHeader('Set-Cookie', cookies);
      } else {
        res?.setHeader('Set-Cookie', cookie);
      }
    } else {
      const cookies = (req as any)?.cookies as Record<string, string>;
      isAttaEditor = cookies[ATTA_EDITOR_KEY] === 'true';
    }
  } else {
    try {
      const response = await request<ResponseBody<SharedConfigs | null>>(
        `${internalHostname()}/shared-configs`,
        {
          headers: { 'am-api-key': AM_API_KEY },
        },
      );

      isAttaEditor =
        shopInfo?.created_at && response.data
          ? checkAttaEditorEnable(shopInfo?.created_at, response.data)
          : false;
    } catch (e) {
      isAttaEditor = false;
    }
  }

  if (!isAttaEditor) return null;

  const schemaList = getSchema();
  const schema: DataMap = {} as DataMap;
  const code: DataMap = {} as DataMap;
  const deps: DataMap = {} as DataMap;

  schemaList.forEach((i) => {
    if (isPreview) {
      schema[i.name] = i.schema;
    } else {
      const { code: c, deps: d } = compileSync(i.schema, loader);
      code[i.name] = c;
      deps[i.name] = d;
    }
  });

  return isPreview
    ? {
        preview: true,
        schema,
      }
    : {
        preview: false,
        code,
        deps,
      };
};
