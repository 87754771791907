import { debounce, uniqueId } from 'lodash-es';
import { useEffect } from 'react';

import { useUpdateItemsRect } from '@aftership/atta-engine/renderer';

import { useIsAttaEditor } from '@/features/returns/hooks/useIsAttaEditor';

export const useScrollUpdate = () => {
  const isAttaEditor = useIsAttaEditor();
  const updateHandler = useUpdateItemsRect();
  const debouncedUpdateHandler = debounce(updateHandler, 30);
  const className = uniqueId('atta-scroll-container-');
  useEffect(() => {
    if (!isAttaEditor) return;
    const dom = document.querySelector('.' + className);
    if (dom) dom.addEventListener('scroll', debouncedUpdateHandler);
    return () => {
      if (dom) dom.removeEventListener('scroll', debouncedUpdateHandler);
    };
  });
  return className;
};
